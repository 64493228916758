import axios from '@axios'

// currently it does not need to fetch article.
const fetchSelection = id => axios.get(`/admin/selections/${id}`)

const getSelections = params => axios.get('/admin/selections', { params })

const storeSelection = data => axios.post('/admin/selections', data)

const updateSelection = (id, data) => axios.put(`/admin/selections/${id}`, data)

const destroySelection = id => axios.get(`/admin/selections/${id}`)

// === items ===

const getItems = params => axios.get('/admin/selection-items', { params })

const destroyItem = id => axios.delete(`/admin/selection-items/${id}`)

const AddSelectionItem = (type, data) => axios.post(`/admin/selection-items/${type}`, data)

export { AddSelectionItem, destroyItem, destroySelection, fetchSelection, getItems, getSelections, storeSelection, updateSelection }
